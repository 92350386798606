import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { screenSizes } from '../../../terhiru-ui/js/consts';
import { isBiggerThanWindowSize } from '../../../terhiru-ui/js/utils';
import './TeamSection.scss';

type teamSectionItem = {
  imageUrl: string,
  headerIntlId: string,
  descIntlIds: string[]
}

function TeamSection() {
  const [isMediumSize, setIsMediumSize] = useState(isBiggerThanWindowSize(screenSizes.md));

  const teamMembersMap: teamSectionItem[] = [
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/AaronPrat.png',
      headerIntlId: 'app.team.team-section.team-member.aaon-p.name',
      descIntlIds: ['app.team.team-section.team-member.aaron-p.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/RaduCirli.png',
      headerIntlId: 'app.team.team-section.team-member.radu-c.name',
      descIntlIds: ['app.team.team-section.team-member.radu-c.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/AyeletAglili.png',
      headerIntlId: 'app.team.team-section.team-member.ayelet-g.name',
      descIntlIds: ['app.team.team-section.team-member.ayelet-g.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/EldarIsgandarov.png',
      headerIntlId: 'app.team.team-section.team-member.eldar-i.name',
      descIntlIds: ['app.team.team-section.team-member.eldar-i.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/BiancaZ.png',
      headerIntlId: 'app.team.team-section.team-member.bianca-z.name',
      descIntlIds: ['app.team.team-section.team-member.bianca-z.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/DavidShawky.png',
      headerIntlId: 'app.team.team-section.team-member.david-s.name',
      descIntlIds: ['app.team.team-section.team-member.david-s.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/AlexMitrache.png',
      headerIntlId: 'app.team.team-section.team-member.alex-m.name',
      descIntlIds: ['app.team.team-section.team-member.alex-m.title'],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/a-new-team/NetanelPrat.png',
      headerIntlId: 'app.team.team-section.team-member.netanelP.name',
      descIntlIds: ['app.team.team-section.team-member.netanelP.title'],
    },
  ]

  const advisoryMembersMap: teamSectionItem[] = [
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/team/Dov-Raviv.png',
      headerIntlId: 'app.team.team-section.advisory-member.dov-r.name',
      descIntlIds: [
        'app.team.team-section.advisory-member.dov-r.title-1',
        'app.team.team-section.advisory-member.dov-r.title-2',
      ],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/team/Ken-Sunshine.png',
      headerIntlId: 'app.team.team-section.advisory-member.ken-s.name',
      descIntlIds: ['app.team.team-section.advisory-member.ken-s.title']
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/team/Dan-Or-Hof.png',
      headerIntlId: 'app.team.team-section.advisory-member.dan-o.name',
      descIntlIds: [
        'app.team.team-section.advisory-member.dan-o.title-1',
        'app.team.team-section.advisory-member.dan-o.title-2'
      ],
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/team/Shahar-Abuhatzira.png',
      headerIntlId: 'app.team.team-section.advisory-member.shahar-a.name',
      descIntlIds: [
        'app.team.team-section.advisory-member.shahar-a.title-1',
        'app.team.team-section.advisory-member.shahar-a.title-2',
      ],
    },
  ]

  useEffect(() => {

    function windowResize() {
      setIsMediumSize(isBiggerThanWindowSize(screenSizes.md))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  function renderMember(item: teamSectionItem, style?: React.CSSProperties) {
    let titleElements = item.descIntlIds.map(intlId => 
      <span key={intlId} className='d-block'><FormattedMessage id={intlId}/></span>  
    )

    return (
      <div key={item.headerIntlId} className='team-grid-item text-center d-flex flex-column align-items-center' style={style}>
        <img src={item.imageUrl} alt='team member' className='team-section-img'/>
        <p><span className='fw-bold'><FormattedMessage id={item.headerIntlId}/></span></p>
        { titleElements }
      </div>
    )
  }

  function renderMembers(list: teamSectionItem[]) {
    return list.map(item => renderMember(item))
  }

  function renderTeamMembers(list: teamSectionItem[]) {
    if(isMediumSize) {
      let elements: JSX.Element[] = []

      elements.push(
        <div className='team-section-grid-3 m-t-3'>
          {
            renderMembers(list.slice(0,9))
          }
        </div>
      )

      return elements;
    }
    return (
      <div className='team-section-grid-2 m-t-3'>
      { renderMembers(list) }
      </div>
    )
  }

  return (
    <div className='container text-center p-y-3'>
      <h3 style={{color: "var(--tse-secondary)"}}><FormattedMessage id='app.team.team-section.the-team'/></h3>
      {renderTeamMembers(teamMembersMap)}

      <h3 style={{color: "var(--tse-secondary)"}} className='m-t-3'><FormattedMessage id='app.team.team-section.advisory'/></h3>
      {renderTeamMembers(advisoryMembersMap)}
    </div>
  );
}

export default TeamSection;
